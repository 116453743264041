// *************************************
//
//  CIS Reports Print
//   -> finances-*
//
// *************************************

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&subset=latin');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700&subset=latin');
@import "variables";

$reports-print-font-family: "Source Sans Pro", "Open Sans", sans-serif;

$document-width: 816px; // this is the actual width of an A4 paper
$document-height: 1105px; // this is the actual height of an A4 paper - would be 1154 if printing margins wont screw sizes

@media print {

  .x-listitem {
    break-inside: avoid;
  }

  @page {
    margin-top: 35px;
  }

  @page :first {
    margin-top: unset;
  }
}

.pageBreakBefore {
  page-break-before: always !important;
  padding-top: 30px;
}

.finances-cisReports-content {
  padding: 30px;

  .finances-cisReports-listItem {
    width: 100%;
    font-family: $reports-print-font-family;
    font-size: 12pt !important;
    font-weight: normal;
    background: transparent;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    padding: 0 0 2em;
    margin: 0 auto 2em;
    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }


    h2 {
      font-family: $reports-print-font-family;
      font-size: 160%;
      line-height: 1.2em;
      small {
        font-family: $reports-print-font-family;
        font-size: 70%;
        line-height: 1em;
      }
    }

    p {
      padding-bottom: 1em;
      margin-bottom: 1em;
      font-size: 100%;
      line-height: 1em;
    }

    .finances-cisReports-listItem-top {
      padding-bottom: 1em;
      margin-bottom: 1em;
      flex-direction: row;

      .details {
        font-size: 80%;

        span {
          line-height: 1em;

          span {
            line-height: 1.3em;
          }

          &.title {
            font-size: 130%;
            font-weight: bold;
          }
        }
      }
    }

    .finances-cisReports-listItem-bottom {}

    .cisReport-table {
      flex-direction: row;
      font-size: 100%;

      .cisReport-table-cell {
        display: block;
        overflow: hidden;
        text-align: right !important;
        padding: 0 0.2em;
        span {
          float: right;
        }
        &.date,
        &.utr,
        &.cisVer,
        &.subCont,
        &.invNum {
          text-align: left !important;
          span {
            float: left;
          }
        }
        &.date {
          min-width: 14%;
        }
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }

        span.label {
          display: none;
        }

      }

      &-header {
        display: flex;
        margin-bottom: 1em;
        .cisReport-table-cell {
          font-size: 80%;
        }
      }
      &-row {
        padding: 0.2em 0;
        .cisReport-table-cell {
          font-size: 80%;
        }
        &:first-child {
          margin-top: 0;
        }
        &:nth-child(odd) {
          background: #f9f9f9;
        }
        &.total,
        &.grandTotal {
          background: transparent;
        }
        &.total {
          padding: 0.2em 0 30px;
          .cisReport-table-cell {
            font-weight: bold;
            font-size: 100%;
          }
        }
      }

      &.grandTotal {
        .cisReport-table-cell {
          font-size: 100%;
        }
      }

    }

  }

  &-monthly {
    padding: 0 30px;
    .finances-cisReports-listItem {
      border-bottom: 0;
      .finances-cisReports-listItem-bottom {
        .cisReport-table-header,
        .cisReport-table-row {
          .cisReport-table-cell {
            min-width: 14.2%;
            max-width: 14.3%;
            &.date {
              min-width: 14%;
            }
          }
        }
      }
    }
  }
  &-contractor {
    .finances-cisReports-listItem {
      .finances-cisReports-listItem-bottom {
        .cisReport-table-header,
        .cisReport-table-row {
          .cisReport-table-cell {
            min-width: 14.2%;
            max-width: 14.3%;
            &.date {
              min-width: 15%;
            }
          }
        }
      }
    }
  }

}
