// *************************************
//
//   Document Reports scss variables
//
// *************************************

@mixin reportBreakPoint($point) {
  // enable this @if when you need a page where no media queries are allowed and set the value 'false' at the top of the view
  //@if ($MQs) {
  $rsln-mobile-small: "only screen and (min-width : 300px)"; // portrait mobile
  $rsln-mobile-medium: "only screen and (min-width : 480px)"; // landscape mobile
  $rsln-desktop-small: "only screen and (min-width : 600px)"; // portrait tablet
  $rsln-desktop-medium: "only screen and (min-width : 801px)"; // landscape tablet
  $rsln-desktop-large: "only screen and (min-width: 1025px)"; // laptops, desktops
  $rsln-desktop-hires: "only screen and (min-width: 1600px)"; // high resolution laptops and desktop

  @if $point == rsln-mobile-small {
    @media #{$rsln-mobile-small} { @content; }
  }
  @else if $point == rsln-mobile-medium {
    @media #{$rsln-mobile-medium} { @content; }
  }
  @else if $point == rsln-desktop-small {
    @media #{$rsln-desktop-small} { @content; }
  }
  @else if $point == rsln-desktop-medium {
    @media #{$rsln-desktop-medium} { @content; }
  }
  @else if $point == rsln-desktop-large {
    @media #{$rsln-desktop-large} { @content; }
  }
  @else if $point == rsln-desktop-hires {
    @media #{$rsln-desktop-hires} { @content; }
  }

  //}
}


//* FONTS ===================================================================================
@import "@powerednow/shared/resources/css/fonts.css";

$reports-print-font-family: "Source Sans Pro", "Open Sans", sans-serif;

$docReports-font-size-base: 16px;

$docReports-font-size-base-normal: 100%;
$docReports-font-size-base-large: 120%;
$docReports-font-size-base-small: 80%;
$docReports-font-size-base-xsmall: 60%;


//* PADDINGS & MARGINS ===================================================================================
$docReports-margin-general: 30px;
$docReports-margin-small: 15px;


//* COLOURS ===================================================================================
$blue: #3498DB;
$gray: #5E5E5E;
$grey: #bdc3c7;

$docReports-title-color: $blue;
$docReports-text-color: $gray;
$docReports-light-color: $grey;

$docReports-color-gray-border: #e2e2e2;
