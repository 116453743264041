/* === Open Sans - regular */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: local("Open Sans Regular"),
		local("OpenSans-Regular"),
		url("../fonts/OpenSans-Regular.woff") format("woff"),
		url("../fonts/OpenSans-Regular.woff2") format("woff2");
}

/* === Open Sans - 700 */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: local("Open Sans Bold"),
		local("OpenSans-Bold"),
		url("../fonts/OpenSans-Bold.woff") format("woff"),
		url("../fonts/OpenSans-Bold.woff2") format("woff2");
}

/* === Source Sans Pro - regular */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	src: local("Source Sans Pro Regular"),
		local("SourceSansPro-Regular"),
		url('../fonts/SourceSansPro-Regular.woff') format('woff'),
		url("../fonts/SourceSansPro-Regular.woff2") format("woff2");
}

/* === Source Sans Pro - 700 */
@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 700;
	src: local("Source Sans Pro Bold"),
		local("SourceSansPro-Bold"),
		url('../fonts/SourceSansPro-Bold.woff') format('woff'),
		url("../fonts/SourceSansPro-Bold.woff2") format("woff2");
}

@font-face {
	font-family: 'proxima_nova_cn_rgregular';
	src: url('../fonts/proximanovacond-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Cousine';
	src: url('../fonts/cousine-regular-webfont.woff') format('woff'),
		 url('../fonts/cousine-regular-webfont.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Cousine';
	src: url('../fonts/cousine-bold-webfont.woff') format('woff'),
	url('../fonts/cousine-bold-webfont.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}

/* === Righteous - Regular */
@font-face {
	font-family: 'Righteous';
	src: url('../fonts/Righteous-Regular.ttf') format('ttf');
	font-weight: 400;
	font-style: normal;
}

/* === Futura Maxi - Bold */
@font-face {
	font-family: 'Futura Maxi CG Bold';
	src: url('../fonts/Futura-Maxi-CG-Bold-Regular.otf') format('otf');
	font-weight: 700;
	font-style: normal;
}
/* latin-ext */
@font-face {
	font-family: 'Dancing Script script=latin rev=1';
	font-style: normal;
	font-weight: 400 700;
	font-display: block;
	src: url(../fonts/DancingScript-latin-ext.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Dancing Script script=latin rev=1';
	font-style: normal;
	font-weight: 400 700;
	font-display: block;

	src: url(../fonts/DancingScript.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
