// *************************************
//
//  Document Reports Print
//   -> finances-*
//
// *************************************
@import "variables";

$reports-print-font-family: "Source Sans Pro", "Open Sans", sans-serif;

$document-width: 816px; // this is the actual width of an A4 paper
$document-height: 1105px; // this is the actual height of an A4 paper - would be 1154 if printing margins wont screw sizes

@media print {

  .x-listitem {
    break-inside: avoid;
  }

  @page {
    margin-top: 35px;
  }

  @page :first {
    margin-top: unset;
  }
}

.pageBreakBefore {
  page-break-before: always !important;
  padding-top: 30px;
}

.report-date-period {
  .main-title {
    font-weight: normal;
    padding: 0;
    margin-bottom: $docReports-margin-small;
    font-size: $docReports-font-size-base-large;
  }
}

.finances-docReports-content {
  //max-width: $document-width - ($docReports-margin-general * 2);
  padding: $docReports-margin-general;
  color: $docReports-text-color;
  font-family: $reports-print-font-family;
  font-size: $docReports-font-size-base;

  .financialSum {
    display: none !important;
  }

  h2 {
    font-weight: normal;
    font-size: $docReports-font-size-base-large;
    color: $docReports-title-color;
    padding: 0;
    margin-bottom: $docReports-margin-small;
  }

  .mainScreenTableRow {
    position: relative;
    padding-bottom: $docReports-margin-general;
    margin-bottom: $docReports-margin-general;
    border-bottom: 1px solid $docReports-color-gray-border;
    font-size: $docReports-font-size-base-normal;

    span {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .mainScreenTableDate {
      color: $docReports-light-color;
      &.hide-on-print {
        display: none !important;
      }
    }

    .mainScreenTableCustomer {
      font-size: $docReports-font-size-base-large;
      color: $docReports-title-color;
      margin-bottom: $docReports-margin-small;
    }

    .mainScreenTableDocId {
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
    }

    .mainScreenTableItemDescription {
      margin-bottom: $docReports-margin-small;
      span:nth-child(2) {
        display: block;
        clear: both;
      }
    }

    .mainScreenTableNET,
    .mainScreenTableTax,
    .mainScreenTableTotal,
    .mainScreenTableCost,
    .mainScreenTableItemCategoryName,
    .mainScreenTableUnitCost,
    .mainScreenTableQuantity {
      margin-bottom: $docReports-margin-small;
      display: flex;
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      box-orient: horizontal;
      -webkit-box-direction: normal;
      -moz-box-direction: normal;
      box-direction: normal;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      flex-direction: row;
      -ms-flex-direction: row;
      -webkit-box-align: stretch;
      -moz-box-align: stretch;
      box-align: stretch;
      -webkit-align-items: stretch;
      -moz-align-items: stretch;
      -ms-align-items: stretch;
      -o-align-items: stretch;
      align-items: stretch;
      -ms-flex-align: stretch;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      box-pack: justify;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      -o-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: justify;
    }

    .mainScreenTableTotal,
    .mainScreenTableCost {
      font-size: $docReports-font-size-base-large;
      margin-bottom: 0;
    }

  }

}
