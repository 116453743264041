// *************************************
//
//   CIS Reports scss variables
//
//  Checked for desktop
//
// *************************************

@mixin reportBreakPoint($point) {
  // enable this @if when you need a page where no media queries are allowed and set the value 'false' at the top of the view
  //@if ($MQs) {
  $rsln-mobile-small: "only screen and (min-width : 300px)"; // portrait mobile
  $rsln-mobile-medium: "only screen and (min-width : 480px)"; // landscape mobile
  $rsln-desktop-small: "only screen and (min-width : 600px)"; // portrait tablet
  $rsln-desktop-medium: "only screen and (min-width : 801px)"; // landscape tablet
  $rsln-desktop-large: "only screen and (min-width: 1025px)"; // laptops, desktops
  $rsln-desktop-hires: "only screen and (min-width: 1600px)"; // high resolution laptops and desktop

  @if $point == rsln-mobile-small {
    @media #{$rsln-mobile-small} { @content; }
  }
  @else if $point == rsln-mobile-medium {
    @media #{$rsln-mobile-medium} { @content; }
  }
  @else if $point == rsln-desktop-small {
    @media #{$rsln-desktop-small} { @content; }
  }
  @else if $point == rsln-desktop-medium {
    @media #{$rsln-desktop-medium} { @content; }
  }
  @else if $point == rsln-desktop-large {
    @media #{$rsln-desktop-large} { @content; }
  }
  @else if $point == rsln-desktop-hires {
    @media #{$rsln-desktop-hires} { @content; }
  }

  //}
}

//* FONTS ===================================================================================
$reports-font-family-primary: "Helvetica Neue", Helvetica, Arial, sans-serif;
$reports-font-family-secondary: 'proxima_nova_cn_rgregular', Helvetica, Arial, 'Lucida Grande', Sans-Serif;

$reports-font-size-base-mobile: 14px;
$reports-font-size-base-tablet: 16px;
$reports-font-size-base-desktop: 18px;

$reports-font-size-base-normal: 100%;
$reports-font-size-base-large: 120%;
$reports-font-size-base-small: 80%;
$reports-font-size-base-xsmall: 60%;


//* PADDINGS & MARGINS ===================================================================================
$reports-margin-general-mobile: 10px;
$reports-margin-general-tablet: 15px;
$reports-margin-general-desktop: 15px;
$reports-margin-general-Ldesktop: 20px;

$reports-margin-general-mobile-small: 8px;
$reports-margin-general-tablet-small: 10px;
$reports-margin-general-desktop-small: 10px;
$reports-margin-general-Ldesktop-small: 12px;


//* COLOURS ===================================================================================
$blue: #3498DB;
$gray: #5E5E5E;

$reports-title-color: $blue;
$reports-text-color: $gray;

$reports-color-gray-border: #e2e2e2;
