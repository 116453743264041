// *************************************
//
//   CIS Reports pages
//   -> finances-*
//
// *************************************

@use "sass:math";

@import "variables";

.finances-cisReports-content {

  .finances-cisReports-listItem {
    color: $reports-text-color;
    font-family: $reports-font-family-primary;

    font-size: $reports-font-size-base-mobile;
    @include reportBreakPoint(rsln-desktop-small) {
      font-size: $reports-font-size-base-tablet;
    }
    @include reportBreakPoint(rsln-desktop-large) {
      font-size: $reports-font-size-base-desktop;
    }

    h2 {
      color: $reports-title-color;
      small {
        display: block;
        clear: both;
        color: $reports-text-color;
        font-family: $reports-font-family-primary;
        font-size: 60%;
      }
    }

    p {
      font-size: $reports-font-size-base-small;
      border-bottom: 1px solid $reports-color-gray-border;

      padding-bottom: $reports-margin-general-mobile;
      margin-bottom: $reports-margin-general-mobile;
      @include reportBreakPoint(rsln-desktop-small) {
        padding-bottom: $reports-margin-general-tablet;
        margin-bottom: $reports-margin-general-tablet;
      }
      @include reportBreakPoint(rsln-desktop-large) {
        padding-bottom: $reports-margin-general-desktop;
        margin-bottom: $reports-margin-general-desktop;
      }
      @include reportBreakPoint(rsln-desktop-hires) {
        padding-bottom: $reports-margin-general-Ldesktop;
        margin-bottom: $reports-margin-general-Ldesktop;
      }

      &.disclaimer {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
        &.blue {
          color: $blue;
        }
      }
    }

    .finances-cisReports-listItem-top {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      border-bottom: 1px solid $reports-color-gray-border;

      flex-direction: column;
      padding-bottom: $reports-margin-general-mobile-small;
      margin-bottom: $reports-margin-general-mobile-small;
      @include reportBreakPoint(rsln-desktop-small) {
        flex-direction :row;
        padding-bottom: $reports-margin-general-tablet-small;
        margin-bottom: $reports-margin-general-tablet-small;
      }
      @include reportBreakPoint(rsln-desktop-large) {
        padding-bottom: $reports-margin-general-desktop-small;
        margin-bottom: $reports-margin-general-desktop-small;
      }
      @include reportBreakPoint(rsln-desktop-hires) {
        padding-bottom: $reports-margin-general-Ldesktop-small;
        margin-bottom: $reports-margin-general-Ldesktop-small;
      }
      .x-ie & {
        display: block;
        @include reportBreakPoint(rsln-desktop-small) {
          display: flex;
        }
      }

      .details {
        flex: 1;
        font-size: $reports-font-size-base-small;

        span {
          display: block;
          clear: both;
          margin-bottom: 5px;
          &:last-child {
            margin-bottom: 0;
          }

          span {
            margin: 0;
            &.label {
              font-weight: bold;
            }
          }

          &.title {
            font-size: 130%;
          }
        }

        p {
          font-size: 100%;
        }
      }
    }

    .finances-cisReports-listItem-bottom {}

    .cisReport-table {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      flex-direction: column;
      @include reportBreakPoint(rsln-desktop-small) {
        flex-direction: row;
      }

      .cisReport-table-cell {
      flex: 1;
      display: flex;
      align-items: flex-end;
        font-size: $reports-font-size-base-small;

        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 3px;
        @include reportBreakPoint(rsln-desktop-small) {
          flex-direction: column;
          justify-content: center;
          margin-bottom: 0;
          text-align: right;
          padding: 0 math.div($reports-margin-general-tablet-small, 2);
        }
        @include reportBreakPoint(rsln-desktop-large) {
          padding: 0 math.div($reports-margin-general-desktop-small, 2);
        }
        @include reportBreakPoint(rsln-desktop-hires) {
          padding: 0 math.div($reports-margin-general-Ldesktop-small, 2);
        }
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }

        &.date,
        &.utr,
        &.cisVer,
        &.subCont,
        &.invoice,
        &.invNum {
          align-items: flex-start;
          text-align: left;
        }
        &.date {
          min-width: 14%;
        }

        &.amtLiab,
        &.lessCost {
          min-width: 13%;
        }

        span.label {
          text-align: left;
          font-weight: bold;
          display: block;
          @include reportBreakPoint(rsln-desktop-small) {
            display: none;
          }
        }

      }

      &-header {
        display: none;
        @include reportBreakPoint(rsln-desktop-small) {
          display: flex;
          margin-bottom: $reports-margin-general-tablet;
        }
        @include reportBreakPoint(rsln-desktop-large) {
          margin-bottom: $reports-margin-general-desktop;
        }
        @include reportBreakPoint(rsln-desktop-hires) {
          margin-bottom: $reports-margin-general-Ldesktop;
        }
        .cisReport-table-cell {
          color: $reports-title-color;
        }
      }
      &-row {
        margin-bottom: $reports-margin-general-mobile-small;
        border-bottom: 1px solid $reports-color-gray-border;
        padding: $reports-margin-general-mobile-small 0;
        @include reportBreakPoint(rsln-desktop-small) {
          margin-bottom: 0;
          border-bottom: 0;
          padding: $reports-margin-general-tablet-small 0;
        }
        @include reportBreakPoint(rsln-desktop-large) {
          padding: $reports-margin-general-desktop-small 0;
        }
        @include reportBreakPoint(rsln-desktop-hires) {
          padding: $reports-margin-general-Ldesktop-small 0;
        }
        &:last-child {
          border-bottom: 0;
        }
        &:nth-child(odd) {
          @include reportBreakPoint(rsln-desktop-small) {
            background: #f9f9f9;
          }
        }

        &.total {
          border-color: $reports-text-color;
          background: transparent;
        }

      }

      &.grandTotal {
        margin-top: $reports-margin-general-mobile-small;
        padding: $reports-margin-general-mobile-small;
        @include reportBreakPoint(rsln-desktop-small) {
          background: transparent;
          margin: $reports-margin-general-tablet-small #{-($reports-margin-general-tablet-small)};
          padding: $reports-margin-general-tablet-small;
        }
        @include reportBreakPoint(rsln-desktop-large) {
          margin: $reports-margin-general-desktop-small #{-($reports-margin-general-desktop-small)};
          padding: $reports-margin-general-desktop-small;
        }
        @include reportBreakPoint(rsln-desktop-hires) {
          margin: $reports-margin-general-Ldesktop-small #{-($reports-margin-general-Ldesktop-small)};
          padding: $reports-margin-general-Ldesktop-small;
        }

        .cisReport-table-cell {
          font-weight: bold;
          font-size: 100%;
          @include reportBreakPoint(rsln-desktop-small) {
            font-size: 80%;
          }
          @include reportBreakPoint(rsln-desktop-large) {
            font-size: 100%;
          }
        }
      }

    }

  }

  &-monthly {

    .finances-cisReports-listItem {
      .finances-cisReports-listItem-bottom {
        .cisReport-table {
          &-row {
            .cisReport-table-cell {
              @include reportBreakPoint(rsln-desktop-small) {
                min-width: 12%;
                max-width: 12%;
              }
              @include reportBreakPoint(rsln-desktop-large) {
                min-width: 12.2%;
                max-width: 12.3%;
              }
              &.date {
                min-width: 14%;
              }
            }
            &.total {
              .cisReport-table-cell {
                font-weight: bold;
                font-size: 100%;
                @include reportBreakPoint(rsln-desktop-small) {
                  font-size: 80%;
                }
                @include reportBreakPoint(rsln-desktop-large) {
                  font-size: 100%;
                }

                &.invNum {
                  display: none;
                  @include reportBreakPoint(rsln-desktop-small) {
                    display: flex;
                  }
                }

                &.date {
                  font-size: 100%;
                  .label {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

  }

  &-contractor {
    margin-top: $reports-margin-general-mobile !important;
    @include reportBreakPoint(rsln-desktop-small) {
      margin-top: $reports-margin-general-tablet !important;
    }
    @include reportBreakPoint(rsln-desktop-large) {
      margin-top: $reports-margin-general-desktop !important;
    }
    @include reportBreakPoint(rsln-desktop-hires) {
      margin-top: $reports-margin-general-Ldesktop !important;
    }

    .finances-cisReports-listItem {
      .cisReport-table {
        &-row {
          .cisReport-table-cell {
            @include reportBreakPoint(rsln-desktop-small) {
              min-width: 14%;
              max-width: 14%;
            }
            @include reportBreakPoint(rsln-desktop-large) {
              min-width: 14.2%;
              max-width: 14.3%;
            }
            &.date {
              min-width: 15%;
            }
          }
          &.total {
            .cisReport-table-cell {
              font-weight: bold;
              font-size: 100%;
              @include reportBreakPoint(rsln-desktop-small) {
                font-size: 80%;
              }
              @include reportBreakPoint(rsln-desktop-large) {
                font-size: 100%;
              }

              &.cisVer,
              &.date,
              &.invNum,
              &.subCont {
                display: none;
                @include reportBreakPoint(rsln-desktop-small) {
                  display: flex;
                }
              }

              &.utr {
                font-size: 100%;
                .label {
                  display: none;
                }
              }
            }
          }
        }
        &.grandTotal {
          .cisReport-table-cell {

            &.utr {
              font-size: 100%;
              .label {
                display: none;
              }
            }

            &.cisVer,
            &.date,
            &.invNum,
            &.subCont {
              display: none;
              @include reportBreakPoint(rsln-desktop-small) {
                display: flex;
              }
            }

          }
        }
      }
    }
  }

  &-subcontractor {
    margin-top: $reports-margin-general-mobile !important;
    @include reportBreakPoint(rsln-desktop-small) {
      margin-top: $reports-margin-general-tablet !important;
    }
    @include reportBreakPoint(rsln-desktop-large) {
      margin-top: $reports-margin-general-desktop !important;
    }
    @include reportBreakPoint(rsln-desktop-hires) {
      margin-top: $reports-margin-general-Ldesktop !important;
    }

    .finances-cisReports-listItem {
      .cisReport-table {
        &.grandTotal {
          margin-bottom: 0;
          .cisReport-table-cell {

            &.date {
              font-size: 100%;
              .label {
                display: none;
              }
            }

            &.customer,
            &.invNum {
              display: none;
              @include reportBreakPoint(rsln-desktop-small) {
                display: flex;
              }
            }

          }
        }
      }
    }
  }

}
