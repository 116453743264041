:host {
  all: initial;
}
.vatReturnHeader {
  margin: 25px 0;

  .vatReturnPeriod {
    margin-bottom: 25px;
    color: #459f76;
    text-align: center;
  }

  .vatReturnCompany {
    display: flex;
    justify-content: space-evenly;

    > div {
      width: 50%;
    }

    .companyDetails,
    .companyAddressDetails {
      position: relative;
      display: inline-block;
      border: 5px solid #2a2a2b;
      border-radius: 3px;
      padding: 5px;
      margin: 5px;
    }
    .companyDetails > div,
    .companyAddressDetails > div {
      z-index: 1;
      position: relative;
    }
    .companyDetails::before,
    .companyAddressDetails::before,
    .companyDetails::after,
    .companyAddressDetails::after {
      content: "";
      position: absolute;
      margin: auto;
      background-color: white;
    }

    .companyDetails::before,
    .companyAddressDetails::before {
      height: calc(100% + 12px);
      width: calc(100% - 30px);
      left: 0;
      right: 0;
      top: -6px;
    }

    .companyDetails::after,
    .companyAddressDetails::after {
      width: calc(100% + 12px);
      height: calc(100% - 30px);
      top: 0;
      bottom: 0;
      left: -6px;
    }
  }
}

.vatReturnWrapper {
  .vatReturnBox {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    p {
      font-size: 90%;
      margin: 5px;
    }

    .vatReturnBoxField {
      padding: 10px;
      background-color: #edf3f1;
      border: solid 1px #459f76;
    }

    .vatReturnBoxDescription {
      flex: 75%;
      border-radius: 5px 0px 0px 5px;
    }

    .vatReturnBoxType {
      flex: 5%;
      background-color: #459f76;
      text-align: center;
      color: white;
    }

    .vatReturnBoxAmount {
      flex: 20%;
      border-radius: 0px 5px 5px 0px;
      padding-left: 40px;
    }
  }
}
